/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-21 10:58:37
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-10-06 09:11:01
 * @FilePath: /settlement-frontend/src/core/directive/interface/settlementEntity.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { NumberOrString } from "../type/common";

export const settlementEntityOverview: SettlementEntityOverview = {
  id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  created_uid: 0,
  updated_uid: 0,
  deleted_uid: 0,
  status: 0,
  type: "",
  data_mode: 0,
  vat_number: "",
  tax_number: "",
  passport_number: "",
  name: "",
  custom_name: "",
  street: "",
  postcode: "",
  city: "",
  country_code: "",
  telephone: "",
  mobile_area_code: "",
  mobile_number: "",
  email: "",
  currency: "",
  iban: "",
  bic: "",
  bank_name: "",
  bank_account_name: "",
  bank_account_number: "",
  insolvent: 0,
  __show: {
    country_iso_2: "",
    created_name: "",
    status: "",
    updated_name: "",
  },
  relation_update: "",
};

interface CommonInterface {
  id: NumberOrString;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_uid: NumberOrString;
  updated_uid: NumberOrString;
  deleted_uid: NumberOrString;
  status: number;
  type: string;
  data_mode: number;
  vat_number: string;
  tax_number: string;
  passport_number: string;
  name: string;
  custom_name: string;
  street: string;
  postcode: string;
  city: string;
  country_code: string;
  telephone: string;
  mobile_area_code: string;
  mobile_number: string;
  email: string;
  currency: string;
  iban: string;
  bic: string;
  bank_name: string;
  bank_account_name: string;
  bank_account_number: string;
  insolvent: number;
}

interface CommonInterfaceShow {
  country_iso_2: string;
  created_name: string;
  status: string;
  updated_name: string;
}

export interface SettlementEntityTable extends CommonInterface {
  __show: CommonInterfaceShow;
}

export interface SettlementEntityCompanyTableFilter {
  status: string;
  vat_number?: string;
  tax_number?: string;
  mobile_number?: string;
  passport_number?: string;
  name: string;
  city: string;
  country_code: string;
}

export interface SettlementEntityOverview extends CommonInterface {
  relation_update: string;
  __show: CommonInterfaceShow;
}
