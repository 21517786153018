
import { defineComponent, ref, onMounted, onUpdated, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import {
  commonBackToList,
  formatDate,
  setModuleBCN,
  testMobile,
} from "@/core/directive/function/common";
import { getSettlementEntityStatus } from "@/core/directive/function/settlementEntity";
import { settlementEntityOverview } from "@/core/directive/interface/settlementEntity";
import { ApiBase, ApiSettlementEntity } from "@/core/api";
import TemplateType from "./TemplateType.vue";
import { TaggingItem } from "@/core/directive/interface/common";
import { DebtorOption } from "@/core/directive/interface/debtor";
import { checkPermissionFlag } from "@/directives";

export default defineComponent({
  name: "settlement-entity-update-template",
  components: { TemplateType },
  props: {
    type: {
      type: Number,
      default: 0, // 0：预览 1：修改
    },
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);

    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const loading = ref(false);
    const formData = ref(Object.assign({}, settlementEntityOverview));

    const rules = ref({
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      mobile_number: [
        // {
        //   type: "number",
        //   asyncValidator: (rule, value) => {
        //     return new Promise<void>((resolve, reject) => {
        //       if (!value) {
        //         reject(t("common.isRequired"));
        //       } else if (!testMobile(value)) {
        //         reject(t("common.enterMobile"));
        //       } else {
        //         resolve();
        //       }
        //     });
        //   },
        // },
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      passport_number: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      vat_number: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      tax_number: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      telephone: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      currency: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
      phoneCode: [] as TaggingItem[],
      entityLoading: false,
      entityOptions: [] as DebtorOption[],
      country: [] as TaggingItem[],
      cityLoading: false,
      cityLoaded: false,
      cityOptions: [] as TaggingItem[],
      statusOptions: [] as TaggingItem[],
    });

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["settlement_entity_status"],
      });
      if (data.code == 0) {
        options.value.statusOptions = data.data.settlement_entity_status.items;
      }
    };

    const getPhoneCodeData = async () => {
      const { data } = await ApiBase.getPhoneCodeData({
        type: "settlement",
      });
      if (data.code == 0) {
        options.value.phoneCode = data.data;
      }
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.country = data.data;
      }
    };

    const countryChange = () => {
      if (formData.value.country_code === "") {
        formData.value.city = "";
      }
    };

    const querySearchAsync = async (
      queryString: string,
      cb: (arg: any) => void
    ) => {
      if (options.value.cityLoading || queryString.trim() === "") return cb([]);
      if (options.value.cityLoaded) {
        let filterArr: TaggingItem[] = options.value.cityOptions.filter(
          (item) =>
            (item.value as string)
              .toUpperCase()
              .indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        return false;
      }
      options.value.cityLoading = true;
      const { data } = await ApiSettlementEntity.cityOption({
        country_code: formData.value.country_code,
        value: queryString,
      });
      options.value.cityLoading = false;
      if (data.code == 0) {
        let arr: TaggingItem[] = [];
        data.data.map((item) => {
          arr.push({
            value: item.name,
            label: item.label,
          });
        });
        options.value.cityOptions = arr;
        cb(arr);
      }
    };

    const goPath = computed(() => {
      return (type: number) => {
        if (type === 0) {
          return (
            "/settlement-master-data/settlement-entity/" +
            route.params.id +
            "/update"
          );
        } else {
          return (
            "/settlement-master-data/settlement-entity/" +
            route.params.id +
            "/overview"
          );
        }
      };
    });

    const getShowInfo = async () => {
      loading.value = true;
      const { data } = await ApiSettlementEntity.getShow({
        ids: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          if (loading.value) return;
          update();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const update = async () => {
      loading.value = true;
      const info = formData.value;
      const { data } = await ApiSettlementEntity.update({
        id: route.params.id,
        status: info.status,
        name: info.name,
        type: info.type,
        custom_name: info.custom_name,
        tax_number: info.tax_number,
        vat_number: info.vat_number,
        email: info.email,
        street: info.street,
        postcode: info.postcode,
        country_code: info.country_code,
        city: info.city,
        bank_name: info.bank_name,
        bank_account_name: info.bank_account_name,
        bank_account_number: info.bank_account_number,
        iban: info.iban,
        bic: info.bic,
        insolvent: info.insolvent,
      });
      loading.value = false;
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          router.replace(goPath.value(1));
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    onMounted(() => {
      getShowInfo();
      if (props.type === 1) {
        getTaggingData();
        getCountryData();
        getPhoneCodeData();
      }
    });

    onUpdated(() => {
      setModuleBCN(t, route, router);
      MenuComponent.reinitialization();
    });

    return {
      formatDate,
      checkPermissionFlag,
      getSettlementEntityStatus,
      formRef,
      loading,
      formData,
      rules,
      options,
      goPath,
      countryChange,
      querySearchAsync,
      getShowInfo,
      submit,
    };
  },
});
