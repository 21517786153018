
import { defineComponent } from "vue";

export default defineComponent({
  name: "settlement-entity-type",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
